<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 g">
      <div class="card card-body">
        <h4>
          <i class="fa fa-calendar"></i>
          {{ e("day-attend-report") }}
          -
          <button
            class="btn btn-success"
            v-b-modal.modal-1
            style="border-radius: 0"
          >
            <i class="fa fa-search"></i>
            {{ e("filter") }}
          </button>
          &nbsp;
          <button
            class="btn btn-danger"
            v-if="filter.type != null"
            style="border-radius: 0"
            @click="reload()"
          >
            <i class="fa fa-refresh"></i>
            {{ e("restore-default") }}
          </button>
        </h4>
        <div class="row">
          <div class="col-12 col-lg-4 c g">
            <div class="form-group">
              <label for="">{{ e("select-input-date") }}</label>
              <input
                v-if="!hijri"
                type="date"
                class="form-control"
                v-model="date"
              />
              <date-picker
                v-if="hijri"
                v-model="date"
                :locale="'ar-sa'"
                :calendar="'hijri'"
              ></date-picker>
              <span v-if="hijri">
                <h2 class="text-danger g text-cneter" v-if="date">
                  {{ date }} هـ
                </h2>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="get()">
            <i class="fa fa-search"></i>
            {{ e("search") }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="reports">
      <div class="card card-body">
        <div class="col-12 table-responsive">
          <button class="btn btn-primary" @click="printData">
            <i class="fa fa-print"></i>
            {{ e("print") }}
          </button>
          <br />
          <br />
          <table
            class="table table-hover table-bordered custom-table"
            ref="printTable"
          >
            <thead>
              <th>
                {{ e("name") }}
              </th>
              <th>
                {{ e("number") }}
              </th>
              <th>
                {{ e("status") }}
              </th>
              <th>
                {{ e("attend-time") }}
              </th>
              <th>
                {{ e("out-time") }}
              </th>
              <th>
                {{ e("lates") }}
              </th>
              <th class="noprint">
                {{ e("options") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="report in reports" :key="report.member._id">
                <td>
                  {{ report.member.name }}
                </td>
                <td>
                  {{ report.member.number }}
                </td>
                <td>
                  <span
                    class="badge bg-primary"
                    v-if="report.reports['status_' + '6']"
                  >
                    {{ e("out-of-time") }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-if="report.reports['status_' + '2']"
                  >
                    {{ e("attend") }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-if="report.reports['status_' + '3']"
                  >
                    {{ e("late") }}
                  </span>
                  <span
                    class="badge bg-danger"
                    v-if="
                      report.reports['status_' + '4'] || !report.reports.lates
                    "
                  >
                    {{ e("ghiab") }}
                  </span>
                </td>
                <td>
                  <div
                    v-if="
                      report.reports['status_' + '2'] ||
                      report.reports['status_' + '3'] ||
                      report.reports['status_' + '6']
                    "
                  >
                    <span v-if="report.reports['status_' + '2']">{{
                      report.reports["status_" + "2"].time
                    }}</span>
                    <span v-if="report.reports['status_' + '3']">{{
                      report.reports["status_" + "3"].time
                    }}</span>
                    <span v-if="report.reports['status_' + '6']">
                      <br />
                      {{ e("out-of-time") }}:
                      {{ report.reports["status_" + "6"].time }}</span
                    >
                  </div>
                </td>
                <td>
                  <div v-if="report.reports['status_' + '5']">
                    {{ report.reports["status_" + "5"].time }}
                  </div>
                </td>
                <td>
                  <div v-if="report.reports.lates">
                    {{ e("the-attend") }}:
                    {{ report.reports.lates.attend }}
                    {{ e("minute") }}
                    |
                    {{ e("freetime") }}:
                    {{ report.reports.lates.break }}
                    {{ e("minute") }}
                  </div>
                </td>
                <td class="noprint">
                  <b-dropdown
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :text="e('options')"
                    variant="relief-secondary btn-sm"
                  >
                    <b-dropdown-item @click="details(report)">
                      <i class="fa fa-list text-success"></i>
                      {{ e("details") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" hide-footer>
      <div>
        <button class="btn btn-block btn-danger btn-lg" @click="reload()">
          <i class="fa fa-refresh"></i>
          {{ e("restore-default") }}</button
        ><br />
        <button
          class="btn btn-block btn-primary btn-lg"
          @click="filterByName()"
        >
          <i class="fa fa-user"></i>
          {{ e("filter-by-name") }}</button
        ><br />
        <button
          class="btn btn-block btn-primary btn-lg"
          @click="filterByNumber()"
        >
          <i class="fa fa-user"></i>
          {{ e("filter-by-number") }}</button
        ><br />
        <button
          @click="filterByPhone()"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-phone"></i>
          {{ e("filter-by-phone") }}</button
        ><br />
        <button
          @click="filterByGroup()"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-cogs"></i>
          {{ e("filter-by-group") }}</button
        ><br />
        <button
          @click="filterByBranche()"
          v-if="user.branches"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-link"></i>
          {{ e("filter-by-branche") }}
        </button>
      </div>
    </b-modal>
    <b-modal id="modal-2" hide-footer>
      <div>
        <div class="form-group">
          <label for="">{{ e("settings-group") }}</label>
          <select
            class="form-control"
            @change="filterByGroupNow()"
            v-model="filter.value"
          >
            <template v-for="group in groups">
              <option :key="group._id" :value="group._id">
                {{ group.title }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-3" hide-footer>
      <div>
        <div class="text-center g">
          <button
            class="btn btn-primary btn-sm"
            @click="filterByBrancheNowALL()"
          >
            {{ e("filter-by-all-branches") }}
          </button>
        </div>
        <div class="form-group">
          <label for="">{{ e("branche") }}</label>
          <select
            class="form-control"
            @change="filterByBrancheNow()"
            v-model="filter.value"
          >
            <template v-for="branche in user.branches_list">
              <option :key="branche.id" :value="branche.id">
                {{ branche.title }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-4" size="lg" hide-footer>
      <div v-if="report.reports">
        <div
          class="alert alert-warning text-center g"
          v-if="!report.reports.lates"
        >
          {{ e("no-results") }}
        </div>
      </div>
      <div class="row" v-if="report.reports">
        <div class="col-12" v-for="(r, i) in report.reports" :key="i">
          <div class="card border" v-if="r.status || r.status == 0">
            <div class="card-header">
              <h4>
                <span class="text-secondary" v-if="r.status == 0">
                  {{ e("start-freetime") }}
                </span>
                <span class="text-secondary" v-if="r.status == 1">
                  {{ e("end-freetime") }}
                </span>
                <span class="text-success" v-if="r.status == 2">
                  {{ e("attend") }}
                </span>
                <span class="text-danger" v-if="r.status == 3">
                  {{ e("late") }}
                </span>
                <span class="text-danger" v-if="r.status == 4">
                  {{ e("ghiab") }}
                </span>
                <span class="text-danger" v-if="r.status == 5">
                  {{ e("out") }}
                </span>
                <span class="text-danger" v-if="r.status == 6">
                  {{ e("out-of-time") }}
                </span>
              </h4>
            </div>
            <div class="card-body">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      {{ e("time") }}
                    </td>
                    <td>
                      {{ r.time }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ e("back_time") }}
                    </td>
                    <td>
                      {{ r.back_time }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ e("device-name") }}
                    </td>
                    <td>
                      {{ r.serial_number }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ e("settings") }}
                    </td>
                    <td>
                      <button class="btn btn-sm" @click="viewSettings(i)">
                        <i class="fa fa-eye"></i>
                        {{ e("view") }}
                      </button>
                      <ul :class="'dnone settings-' + i">
                        <li>
                          {{ e("allowed-days") }}:
                          <ul>
                            <li
                              v-for="(d, v) in r.settings_group.days"
                              :key="v"
                            >
                              {{ e(v) }}:
                              <span v-if="d == 'true'">{{ e("yes") }}</span>
                              <span v-if="d == 'false'">{{ e("no") }}</span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {{ e("start_date") }}:
                          {{ r.settings_group.start_date }}
                        </li>
                        <li>
                          {{ e("late_date") }}:
                          {{ r.settings_group.late_date }}
                        </li>
                        <li>
                          {{ e("ghiab_date") }}:
                          {{ r.settings_group.ghiab_date }}
                        </li>
                        <li>
                          {{ e("end_date") }}:
                          {{ r.settings_group.end_date }}
                        </li>
                        <li>
                          {{ e("is-continued") }}:
                          <span v-if="r.settings_group.iscontinued">{{
                            e("yes")
                          }}</span>
                          <span v-if="!r.settings_group.iscontinued">{{
                            e("no")
                          }}</span>
                        </li>
                        <li>
                          {{ e("is-there-freetimes") }}:
                          <span v-if="r.settings_group.freetimes.length"
                            >{{ e("yes") }}
                            <ul>
                              <li
                                v-for="a in r.settings_group.freetimes"
                                :key="a.start"
                              >
                                {{ e("from") }}: {{ a.start }} الى
                                {{ e("to") }}: {{ a.end }}
                              </li>
                            </ul></span
                          >
                          <span
                            v-if="r.settings_group.iscontinued.length == 0"
                            >{{ e("no") }}</span
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ e("lates") }}
                    </td>
                    <td>
                      <div v-if="r.lates">
                        <span v-if="r.lates.attend != 0"
                          >{{ e("the-attend") }}:
                          {{ r.lates.attend }}
                          {{ e("minute") }}</span
                        >
                        <span v-if="r.lates.break != 0">
                          |
                          {{ e("freetime") }}:
                          {{ r.lates.break }}
                          {{ e("minute") }}</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueDatetimeJs from "vue-datetime-js";
import Ripple from "vue-ripple-directive";
var moment = require("moment-timezone");
var moment2 = require("moment-hijri");
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
import { BOverlay, VBModal, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    datePicker: VueDatetimeJs,
    VBModal,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      date: null,
      hijri: false,
      reports: null,
      filter: { type: null },
      groups: [],
      report: {},
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    if (cdate("2022-02-02").includes("ه")) {
      this.hijri = true;
    } else {
      this.date = new Date().toISOString().split("T")[0];
    }
    var g = this;
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        g.loading = false;
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },

    groupName(id) {
      var title = null;
      this.groups.forEach((element) => {
        if (element._id == id) {
          title = element.title;
        }
      });
      return title;
    },
    filterByName() {
      var f = prompt(e("filter-by-name"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "name";
        this.filter.value = f;
        this.page = 0;
        this.get();
      }
    },
    filterByNumber() {
      var f = prompt(e("filter-by-number"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "number";
        this.filter.value = f;
        this.page = 0;
        this.get();
      }
    },
    filterByPhone() {
      var f = prompt(e("filter-by-phone"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "phone";
        this.filter.value = f;
        this.page = 0;
        this.get();
      }
    },
    filterByGroup() {
      var g = this;
      this.$bvModal.hide("modal-1");
      setTimeout(() => {
        g.$bvModal.show("modal-2");
      }, 100);
    },
    filterByGroupNow() {
      this.filter.type = "group";
      this.$bvModal.hide("modal-2");
      this.page = 0;
      this.get();
    },
    filterByBranche() {
      var g = this;
      this.$bvModal.hide("modal-1");
      setTimeout(() => {
        g.$bvModal.show("modal-3");
      }, 100);
    },
    filterByBrancheNowALL() {
      this.filter.type = "all_branches";
      this.$bvModal.hide("modal-3");
      this.page = 0;
      this.get();
    },
    filterByBrancheNow() {
      this.filter.type = "branche";
      this.$bvModal.hide("modal-3");
      this.page = 0;
      this.get();
    },
    reload() {
      this.$bvModal.hide("modal-1");
      this.filter.type = null;
      this.filter.value = "";
      this.page = 0;
      this.get();
    },
    get() {
      var g = this,
        _g = this,
        date = this.date;
      _g.loading = true;
      if (cdate("2222-22-22").includes("ه")) {
        date = a2e(date);
        date = moment2(date, "iYYYY/iM/iD").format("YYYY-MM-DD");
      }
      $.post(api + "/reports/day", {
        jwt: this.user.jwt,
        date: date,
        filter: this.filter,
        number: window.location.href.split("number=").length
          ? window.location.href.split("number=")[1]
          : null,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 200) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response.code),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            _g.reports = r.response.arr;
          }
          _g.loading = false;
        })
        .catch((ea) => {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error") + ea,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
    details(report) {
      this.report = report;
      this.$bvModal.show("modal-4");
    },
    viewSettings(i) {
      $(".settings-" + i).toggle();
    },
    printData() {
      var divToPrint = this.$refs.printTable;
      var newWin = window.open("");
      newWin.document.write(`
      <style>
      .noprint{
        display: none;
      }
      table, th, td {
        border: 1px solid;
      }
      td,th{
        padding: 4px
      }
      table{
        width: 100%; border-collapse: collapse;
      }
      </style>
      `);
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
  },
};
</script>
